import React from 'react';
import PropTypes from 'prop-types';

import styles from './Callout.module.scss';

const Callout = (props) => (
  <div className={`${styles.root} ${props.className}`}>
    <div className={styles.contentContainer}>
      <div className={styles.content}>
        { props.children }
      </div>
    </div>
  </div>
);

Callout.defaultProps = {
  className: ''
};

Callout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default Callout;
