import React from 'react';
import PropTypes from 'prop-types';

import styles from './Input.module.scss';
import CalloutArrow from '../components/callouts/CalloutArrow';
import Callout from '../components/callouts/Callout';
import PasswordDescription from '../passwords/PasswordDescription';

// Some browsers attempt to restore form state when navigating back to the registration
// page (via the browser's back button). This interacts poorly with React, causing checkboxes
// to appear checked inconsistent with the React state. The fix is to randomise the name
// attribute of the text boxes. We do not do this with other input types, as this breaks
// autocompletion.
const SESSION_ID = Math.random();

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isFocused: false };
  }

  getEventValue = event => {
    const { type } = this.props;
    return type === 'checkbox' ? event.target.checked : event.target.value;
  };

  handleInputChange = event => {
    const { onChange, name } = this.props;
    const value = this.getEventValue(event);

    if (onChange) {
      onChange(name, value);
    }
  };

  handleInputFocus = () => {
    this.setState({ isFocused: true });
  };

  handleInputBlur = event => {
    const { onBlur, name } = this.props;
    const value = this.getEventValue(event);

    this.setState({ isFocused: false });

    if (onBlur) {
      onBlur(name, value);
    }
  };

  render() {
    const {
      name,
      type,
      label,
      value,
      error,
      hideValidationMessage,
      showValidationBorder,
      passwordPolicy,
      disabled,
      maxLength,
      hidden
    } = this.props;
    const { isFocused } = this.state;
    const isCheckbox = type === 'checkbox';
    const showPasswordCallout = isFocused && passwordPolicy;

    return <label className={isCheckbox ? styles.checkbox : styles.text} hidden={hidden}>
      {!isCheckbox && label}
      {showPasswordCallout && <Callout
        className={styles.passwordCalloutComponent}>
        <PasswordDescription passwordPolicy={passwordPolicy} password={value} />
      </Callout>}
      {showPasswordCallout && <CalloutArrow className={styles.passwordCalloutComponent} />}
      <input
        className={showValidationBorder ? (error ? styles.invalidBorder : styles.validBorder) : undefined}
        name={isCheckbox ? `checkbox-${name}-${SESSION_ID}` : name}
        type={type}
        disabled={disabled}
        hidden={hidden}
        maxLength={maxLength}
        value={isCheckbox ? undefined : value}
        checked={isCheckbox ? value : undefined}
        onFocus={this.handleInputFocus}
        onBlur={this.handleInputBlur}
        onChange={this.handleInputChange} />
      {isCheckbox && label}
      {!hideValidationMessage && error && <p data-testid="validationError" className={styles.error}>{error}</p>}
    </label>;
  }
}

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'password', 'checkbox', 'tel', 'email']),
  hideValidationMessage: PropTypes.bool,
  showValidationBorder: PropTypes.bool,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  maxLength: PropTypes.string,
  label: PropTypes.node,
  value: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  passwordPolicy: PropTypes.string
};

Input.defaultProps = {
  disabled: false,
  hideValidationMessage: false,
  showValidationBorder: false
};

export default Input;
