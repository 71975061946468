import React from 'react';

import styles from './Button.module.scss';

function Button(props) {
  return <button
    disabled={props.disabled}
    tabIndex={0}
    onClick={props.onClick}
    className={`${styles.root} ${props.className}`}>
    { props.children }
  </button>;
}

export default Button;
