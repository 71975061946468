import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle  } from '@fortawesome/free-solid-svg-icons';

import styles from './PasswordLabel.module.scss';
import { PASSWORD_POLICY_PROPTYPE } from './proptypes';
import PasswordDescription from './PasswordDescription';
import CalloutArrow from '../components/callouts/CalloutArrow';
import Callout from '../components/callouts/Callout';

class PasswordLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isCalloutVisible: false };
  }

  handleInfoIconClick = e => {
    const { isCalloutVisible } = this.state;

    e.preventDefault();
    this.setState({ isCalloutVisible: !isCalloutVisible });
  };

  handleBlur = e => {
    this.setState({ isCalloutVisible: false });
  };

  render() {
    const { passwordPolicy } = this.props;
    const { isCalloutVisible } = this.state;

    return <span className={styles.root} tabIndex="-1" onBlur={this.handleBlur}>
      {
        isCalloutVisible &&
        <Callout>
          <PasswordDescription passwordPolicy={passwordPolicy} />
        </Callout>
      }
      Password <div className={styles.infoIcon}>
        {isCalloutVisible && <CalloutArrow />}
        <FontAwesomeIcon icon={faInfoCircle} onClick={this.handleInfoIconClick} />
      </div>
    </span>;
  }
}

PasswordLabel.defaultProps = {
  className: ''
};

PasswordLabel.propTypes = {
  passwordPolicy: PASSWORD_POLICY_PROPTYPE.isRequired,
  className: PropTypes.string
};

export default PasswordLabel;
