import React from 'react';

import styles from './Screen.module.scss';

import { ReactComponent as PlexusLogo } from './plexus-logo.svg';
import PromotionWizardLogo from './promotion-wizard-logo.png';
import getCountry from '../query-string-helpers/getCountry';
import getCountryQueryString from '../query-string-helpers/getCountryQueryString';

function Screen(props) {
  const country = getCountry();

  function handlePhoneClick(e) {
    if (process.env.REACT_APP_DEPLOY_ENV === "production") {
      window.dataLayer.push({
        'event': 'generate_lead',
        'category': 'pwreg_phone',
        'label': 'https://registration.plexus.co',
      });
      window.gtag('event', 'conversion', {
        'send_to': 'AW-961111800/XbQzCNHHqLoDEPjNpcoD',
        'event_callback': 'https://registration.plexus.co'
      });
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'event', {
        'event_label': 'https://registration.plexus.co',
        'event_category': 'pwreg_phone'
      });
      window.fbq('track', 'Lead', {
        'content_category': 'pwreg_phone',
        'content_name': 'https://registration.plexus.co'
      });
      window.lintrk('track', {
        conversion_id: '7666988'
      });
    }
  }

  return <div className={styles.root}>
    <div className={styles.plexusLogoContainer}>
      <a href={'https://www.plexus.co'}>
        <PlexusLogo className={styles.plexusLogo} />
      </a>
    </div>
    <div className={styles.left}>
      <div className={styles.promotionWizardLogo}>
        <img src={PromotionWizardLogo} alt="Promotion Wizard" />
      </div>
      <p>Submit your brief via the self-service platform in less than 15 minutes.</p>
      <p>We then guarantee a perfect set of Terms & Conditions in {country === 'UK' ? 48 : 24} hours or a full refund.</p>
      {country !== 'UK' &&
        <p>Stuck? Call <a onClick={handlePhoneClick} href="tel:1300983907">1300 983 907</a> to speak to us.</p>
      }
      {country === 'UK' &&
        <p>Stuck? Email <a href="mailto:promotionuk@plexus.co">promotionuk@plexus.co</a> to speak to us.</p>
      }
      {country !== 'UK' && <p className={styles.smallText}>
        You're on the Promotion Wizard Australia site.
        If you want to advertise a promotion in the UK,
        click <a href={`${process.env.PUBLIC_URL}?${getCountryQueryString('UK')}`}>here</a> to go to
        Promotion Wizard UK.
      </p>}
      {country === 'UK' && <p className={styles.smallText}>
        You're on the Promotion Wizard UK site.
        If you want to advertise a promotion in Australia,
        click <a href={`${process.env.PUBLIC_URL}?${getCountryQueryString('AU')}`}>here</a> to go to
        Promotion Wizard Australia.
      </p>}
    </div>
    <div className={styles.body}>
      {props.children}
    </div>
  </div>;
}

export default Screen;
