import React from 'react';
import createPasswordPolicy from 'password-sheriff';
import PropTypes from 'prop-types';
import Util from 'util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faTimesCircle, faExclamationCircle  } from '@fortawesome/free-solid-svg-icons';

import { PASSWORD_POLICY_PROPTYPE } from './proptypes';
import styles from './PasswordDescription.module.scss';

function getRuleIcon(verified, isChild) {
  if (verified) {
    return <FontAwesomeIcon icon={faCheckCircle}/>;
  } else if (verified === undefined) {
    // verified is undefined when password-sheriff is just explaining rules, rather than verifying them
    return '\u2022 ';
  } else if (isChild) {
    return <FontAwesomeIcon icon={faCircle} />;
  } else {
    return <FontAwesomeIcon icon={faTimesCircle} />;
  }
}

function getRuleStyle(verified, isChild) {
  if (verified) {
    return styles.requirementValid;
  } else if (isChild) {
    return styles.requirementIgnored;
  } else {
    return styles.requirementInvalid;
  }
}

function renderRule(rule, isChild) {
  const { format: messageArguments, message, verified, code, items } = rule;
  const formattedMessage = messageArguments ? Util.format(message, ...messageArguments) : message;

  return <div key={code}>
    <div className={getRuleStyle(verified, isChild)}>
      { getRuleIcon(verified, isChild) }
      { formattedMessage }
    </div>
    { items && <div className={styles.ruleChildren}>
      { items.map(item => renderRule(item, true)) }
    </div> }
  </div>;
}

function PasswordDescription(props) {
  const { passwordPolicy, password } = props;
  const hasPassword = password !== undefined;
  const passwordRules = hasPassword
    ? createPasswordPolicy(passwordPolicy).missing(password || '').rules
    : createPasswordPolicy(passwordPolicy).explain();

  return <div className={`${styles.root} ${hasPassword ? styles.withPassword : ''}`}>
    <div className={styles.heading}><FontAwesomeIcon icon={faExclamationCircle} />Password security requirements</div>
    <p className={styles.text}>Your password must be:</p>
    { passwordRules.map(rule => renderRule(rule, false)) }
  </div>;
}

PasswordDescription.propTypes = {
  password: PropTypes.string,
  passwordPolicy: PASSWORD_POLICY_PROPTYPE.isRequired
};

export default PasswordDescription;
