import PropTypes from 'prop-types';

export const PASSWORD_RULE_PROPTYPES = {
  code: PropTypes.string.isRequired,
  format: PropTypes.array,
  message: PropTypes.string.isRequired,
  verified: PropTypes.bool.isRequired
};

export const PASSWORD_REPORT_PROPTYPE = PropTypes.shape({
  rules: PropTypes.arrayOf(PropTypes.shape({
    ...PASSWORD_RULE_PROPTYPES,
    items: PropTypes.arrayOf(PropTypes.shape(PASSWORD_RULE_PROPTYPES))
  })).isRequired
});

export const PASSWORD_POLICY_PROPTYPE = PropTypes.oneOf(['none', 'low', 'fair', 'good', 'excellent']);
