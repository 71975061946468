import React from 'react';
import PropTypes from 'prop-types';

import styles from './CalloutArrow.module.scss';

const CalloutArrow = props => (
  <div className={`${styles.root} ${props.className}`}>
    <div className={styles.arrowBorder} />
    <div className={styles.arrow} />
  </div>
);

CalloutArrow.defaultProps = {
  className: ''
};

CalloutArrow.propTypes = {
  className: PropTypes.string
};

export default CalloutArrow;
